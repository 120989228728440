import http from 'axios';
import { SHA1 } from 'crypto-es/lib/sha1';
import delay from 'delay';
import { v4 as uuidv4 } from 'uuid';
import { format, subDays, min, parseISO } from 'date-fns';
import DateExpression from '../utils/DateExpression';
import log from '../utils/log';
import reportProgress from '../utils/reportProgress';
import getNextDate, {
  splitDatesIntoIntervals,
} from '../utils/DateCalculations';

const noOp = () => {};
const METHODS = {
  reportSuites: 'Company.GetReportSuites',
  metadata: 'ReportSuite.GetSettings',
  reportQueue: 'Report.Queue',
  reportGet: 'Report.Get',
};
const YESTERDAY = format(subDays(new Date(), 1), 'yyyy-MM-dd');

const createUsernameToken = (username, secret) => {
  const nonce = uuidv4();
  const created = new Date().toISOString();
  const digest = btoa(SHA1(nonce + created + secret).toString());
  const tokenData = {
    Username: username,
    PasswordDigest: digest,
    Nonce: nonce,
    Created: created,
  };

  return Object.entries(tokenData)
    .map(([key, val]) => `${key}="${val}"`)
    .join(', ');
};

const AA = (
  username,
  secret,
  baseURL = 'https://api.omniture.com/admin/1.4/rest/',
  DELAY = 1000,
) => {
  const request = (method, body, params) =>
    http
      .post(baseURL, body, {
        headers: {
          'X-WSSE': `UsernameToken ${createUsernameToken(username, secret)}`,
        },
        params: {
          method,
          ...params,
        },
      })
      .then(({ data }) => data);

  const getMultiReport = async (data, multithreaded) => {
    const threadCount = multithreaded ? 4 : 1;
    let actualStartDate = DateExpression(data.startDate);
    const parsedEndDate = DateExpression(data.endDate);

    if (data.incrementValue) {
      actualStartDate = getNextDate(data.incrementValue, data.granularity);
    }

    if (data.incrementValue && actualStartDate > parsedEndDate) {
      // Fail-safe for incremental update
      return [
        {
          report: {
            type: 'trended',
            reportSuite: {
              id: data.reportSuite.rsid,
              name: data.reportSuite.site_title,
            },
            period: '',
            elements: data.dimensions.map((dimension) => {
              return {
                id: dimension['id'],
                name: dimension['name'],
              };
            }),
            metrics: data.metrics,
            data: [],
            totals: [],
            version: '1.4.18.10',
          },
          waitSeconds: 0,
          runSeconds: 0,
        },
      ];
    }

    let testIntervals = splitDatesIntoIntervals(
      actualStartDate,
      parsedEndDate,
      data.granularity,
      threadCount,
    );

    let runningReports = testIntervals.map(function (dateRange) {
      return getPaginatedReport({
        ...data,
        startDate: dateRange[0],
        endDate: dateRange[1],
      });
    });
    let responses = await Promise.all(runningReports);

    let results = [];
    responses.forEach((response) => {
      response.forEach((page) => results.push(...page.report.data));
    });

    let result = [responses[0][0]];

    // Todo: this is not 100% correct because some keys of the report like
    // period and totals (which are not used now) would be wrong, just for the first page
    result[0].report.data = results;

    return result;
  };

  const getReport = async (
    reportSuite,
    startDate = YESTERDAY,
    endDate = YESTERDAY,
    granularity = 'day',
    metrics = [],
    dimensions = [],
    segments = [],
    top = 100,
  ) => {
    reportProgress('Requesting data from Adobe');

    const reportData = {
      reportDescription: {
        // Support both `theId` and `{ id: 'theId', other: 'stuff' }`
        reportSuiteID: reportSuite.rsid || reportSuite,
        metrics: metrics.map((x) => ({ id: x.id || x })),
        elements: dimensions.map((x) => ({
          id: x.id || x,
          top: x.top || top,
          startingWith: x.startingWith, // NOTE! 1-based indexing
          classification: x.classification,
        })),
        segments: segments.map((x) => ({ id: x.id || x })),
        dateGranularity: granularity !== 'none' ? granularity : undefined,
        dateFrom: String(startDate).startsWith('c')
          ? DateExpression(startDate)
          : format(parseISO(startDate), 'yyyy-MM-dd'),
        dateTo: String(endDate).startsWith('c')
          ? DateExpression(endDate)
          : format(min([parseISO(endDate), new Date()]), 'yyyy-MM-dd'),
      },
    };
    const { reportID } = await request(METHODS.reportQueue, reportData);
    log(`Submitting request for report ${reportID} to Adobe...`);

    let report;
    for (let i = 0; !report; ++i) {
      try {
        reportProgress(`Waiting for Adobe to return data (try: ${i})`);
        log(
          `Querying Adobe to see if report ${reportID} is ready... (try: ${i})`,
        );
        report = await request(METHODS.reportGet, {
          reportID,
        });
      } catch (error) {
        // If the error is `report_not_ready` then it's not exactly an error so we don't break the execution, we wait and try again.
        if (
          (((error.response || {}).data || {}).error || '') ===
          'report_not_ready'
        ) {
          reportProgress(
            `Adobe replied: Data not ready yet, please wait... (try: ${i})`,
          );
          log(
            `Adobe replied: Report ${reportID} not ready yet, retry later... (try: ${i})`,
          );
        } else {
          log(
            `Adobe reported the following error for report ${reportID}: ${error.message}`,
          );
          throw error;
        }
        await delay(DELAY);
      }
    }
    reportProgress(`Received report ${reportID}`);
    log(`Received report ${reportID} from Adobe:`);

    return report;
  };

  const findIncompleteBreakdowns = (data = [], level, top) => {
    let incomplete = {};

    data.forEach((x) => {
      if (x.breakdown && x.breakdown.length >= top) {
        incomplete[level] = true;
      }
      incomplete = {
        ...incomplete,
        ...findIncompleteBreakdowns(x.breakdown, level + 1, top),
      };
    });

    return incomplete;
  };

  const getPaginatedReport = async (data) => {
    const {
      reportSuite,
      startDate,
      endDate,
      granularity,
      metrics,
      dimensions,
      segments,
      onPage = noOp,
      maxTop = 50000,
    } = data;
    let { top } = data;

    if (!top) {
      top = +Infinity;
    }
    const limitedTop = Math.min(top, maxTop);
    const pages = [];
    let incompleteLevels = [];

    do {
      const report = await getReport(
        reportSuite,
        startDate,
        endDate,
        granularity,
        metrics,
        dimensions,
        segments,
        limitedTop,
      );
      pages.push(report);
      onPage(report);

      if (top <= maxTop) {
        break;
      }

      incompleteLevels = Object.keys(
        findIncompleteBreakdowns(report.report.data, 0, limitedTop),
      );

      const deepest = Math.max(...incompleteLevels, -1);
      if (deepest > -1) {
        dimensions[deepest].startingWith =
          (dimensions[deepest].startingWith || 1) + limitedTop;
      }

      for (let i = deepest + 1; i < dimensions.length; i++) {
        dimensions[i].startingWith = 1;
      }
    } while (incompleteLevels.length);

    return pages;
  };

  return {
    getReportSuites: () => request(METHODS.reportSuites),
    getMetadata: (reportSuite) =>
      request(METHODS.metadata, {
        rsid_list: [reportSuite.rsid || reportSuite],
      }),
    getReport,
    getPaginatedReport,
    getMultiReport,
    username,
  };
};

export default AA;
