import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MyInput from './MyInput';

const Granularity = (props) => (
  <MyInput select {...props}>
    <MenuItem value="none">None</MenuItem>
    <MenuItem value="hour">Hourly</MenuItem>
    <MenuItem value="day">Daily</MenuItem>
    <MenuItem value="week">Weekly</MenuItem>
    <MenuItem value="month">Monthly</MenuItem>
    <MenuItem value="quarter">Quarterly</MenuItem>
    <MenuItem value="year">Yearly</MenuItem>
  </MyInput>
);

export default Granularity;
