import React, { useEffect, useState, useCallback } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import http from 'axios';
import { format } from 'date-fns';
import { Form } from 'formik';
import authenticator from './authenticator';
import CodeFormatter from './CodeFormatter';
import DateRange from './DateRange';
import extractError from './extractError';
import getData from './getData';
import getSchema from './getSchema';
import Granularity from './Granularity';
import Login from './Login';
import LoginAA from './LoginAA';
import { ReactComponent as Logo } from './logo.svg';
import Metadata from './Metadata';
import MyInput from './MyInput';
import ReportSuite from './ReportSuite';
import Sidebar from './Sidebar';
import SortableTable from './SortableTable';
import Username from './Username';
import './tableauWorkaround.css';
import AdobeAnalytics from './connectors/aa';
import log from './utils/log';

const isDebug =
  process.env.REACT_APP_DEBUG || document.location.hash.indexOf('debug') > 0;

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const formatDate = (date) =>
  String(date).startsWith('c') ? date : format(new Date(date), 'yyyy-MM-dd');

function SubApp({
  values,
  setFieldError,
  setFieldTouched,
  setFieldValue,
  isValid,
  user,
  setUser,
}) {
  const [AA, setAA] = useState();
  const [preview, setPreview] = useState({});
  const [options, setOptions] = useState(null);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [newlyAddedCredentials, setNewlyAddedCredentials] = useState([]);

  const setUserAndToken = (data) => {
    setUser(data);
    if (data.token) {
      localStorage.setItem('token', data.token);
    } else {
      localStorage.removeItem('token');
    }
  };

  const onPreview = useCallback(
    async (values) => {
      const [{ columns }] = getSchema(
        values.metrics,
        values.dimensions,
        values.granularity,
      );
      setPreview({
        columns,
      });

      try {
        const rows = [];
        log(
          `Pulling data from Adobe Analytics for account ${
            values.reportSuite.rsid || values.reportSuite
          }`,
        );
        getData(
          await AA.getPaginatedReport({
            ...values,
            startDate: formatDate(values.startDate),
            endDate: formatDate(values.endDate),
            top: Math.min(10, parseInt(values.top) || 10),
          }),
          (newRows) => newRows.forEach((row) => rows.push(row)),
        );
        setPreview({
          columns,
          rows: rows.slice(0, 50),
        });
      } catch (error) {
        setPreview({
          columns,
          error: `Error: ${extractError(error)}`,
        });
      }
    },
    [AA],
  );

  let username = window.tableau.username || '';
  let usernameAlias = window.tableau.usernameAlias || '';
  let password = window.tableau.password || '';
  let connectionData = {};
  try {
    connectionData = JSON.parse(window.tableau.connectionData);
    // Backward compatibility, if no provider is set, we suppose it is AA
    if (connectionData.provider && connectionData.provider !== 'aa') {
      username = '';
      usernameAlias = '';
      password = '';
      connectionData = {};
    }
  } catch (err) {}

  useEffect(() => {
    const token = localStorage.getItem('token');
    let usernameLocal = username;
    let passwordLocal = password;

    if (token && !usernameLocal) {
      // We need to set a username to trigger token authentication
      usernameLocal = 'daexus';
      passwordLocal = token;
    }

    if (usernameLocal && passwordLocal) {
      setAA(null);
      authenticator(usernameLocal, passwordLocal, usernameAlias)
        .then(({ user, username, secret }) => {
          setUserAndToken(user);
          if (username && secret) {
            setAA(AdobeAnalytics(username, secret));
          }
        })
        .catch((err) => {
          setUserAndToken({ error: extractError(err) });
          setAA();
        });
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{ position: 'relative' }}>
          <div style={{ textAlign: 'center' }}>
            <Logo />
            <span style={{ display: 'block', fontSize: 11 }}>
              Catalyst Connector
            </span>
          </div>
          {user.email ? (
            <IconButton
              onClick={handleClick}
              color="inherit"
              style={{ position: 'absolute', right: 0, top: 8 }}
            >
              <AccountCircle />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              disableElevation
              onClick={handleClick}
              style={{
                textTransform: 'none',
                display: 'block',
                position: 'absolute',
                right: 24,
                top: 12,
                color: '#ffffff',
                borderColor: '#ffffff',
              }}
            >
              Login
            </Button>
          )}
          <Backdrop open={open} />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: 16 }}>
              {user.email ? (
                <>
                  <div style={{ textAlign: 'center' }}>{user.email}</div>
                  <div style={{ textAlign: 'center' }}>
                    Plan: {String(user.plan || 'free').toUpperCase()}
                  </div>
                  <Button
                    href="https://profile.daexus.io/"
                    target="_blank"
                    color="primary"
                    fullWidth
                  >
                    Profile
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setUserAndToken({});
                      setAA();
                      setNewlyAddedCredentials([]);
                      setFieldValue('username', '');
                      setFieldValue('password', '');
                      handleClose();
                    }}
                    fullWidth
                  >
                    Sign out
                  </Button>
                </>
              ) : (
                <form id="loginForm">
                  <Login disabled={user.loading} margin="dense" />
                  <Button
                    href="https://www.daexus.io/products/#pricing"
                    target="_blank"
                    fullWidth={false}
                    color="primary"
                    style={{ textTransform: 'inherit', marginTop: 8 }}
                  >
                    Purchase a plan
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      user.loading || !values.dEmail || !values.dPassword
                    }
                    endIcon={user.loading && <CircularProgress size={20} />}
                    style={{ float: 'right', marginTop: 8 }}
                    onClick={async (event) => {
                      event.preventDefault();
                      try {
                        setUserAndToken({ loading: true });
                        const { data } = await http.post(
                          `${window.DAEXUS_API_URL}/user/login`,
                          {
                            email: values.dEmail,
                            password: values.dPassword,
                          },
                        );
                        setUserAndToken(data);
                        setFieldValue('username', '');
                        setFieldValue('password', '');
                        setAA();
                        setNewlyAddedCredentials([]);
                        handleClose();
                      } catch (err) {
                        setFieldTouched('dPassword', true, false);
                        setFieldError('dPassword', extractError(err));
                        setUserAndToken({});
                      }
                    }}
                  >
                    Login
                  </Button>
                </form>
              )}
            </div>
          </Popover>
        </Toolbar>
      </AppBar>
      <Sidebar licenses={user.licenses} />
      <div className={classes.offset} />
      <div className={classes.content}>
        <Form>
          {user.plan === 'pro' || user.plan === 'server' ? (
            <>
              <Username
                label="Credential"
                setAA={setAA}
                token={user.token}
                options={options}
                setOptions={setOptions}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={!values.username || loading}
                endIcon={
                  loading &&
                  AA === null && (
                    <CircularProgress size={20} style={{ color: '#e8c94b' }} />
                  )
                }
                onClick={async () => {
                  try {
                    setLoading(true);
                    setFieldValue('reportSuite', null);
                    setFieldValue('metrics', []);
                    setFieldValue('dimensions', []);
                    setFieldValue('segments', []);
                    setAA(null);
                    const provider = AdobeAnalytics(
                      values.username,
                      values.password,
                    );
                    await provider.getReportSuites();

                    if (
                      options.length === 0 &&
                      !newlyAddedCredentials.find(
                        (x) =>
                          x.username === values.username &&
                          x.password === values.password,
                      )
                    ) {
                      await http.post(
                        `${window.DAEXUS_API_URL}/credential`,
                        {
                          username: values.username,
                          password: values.password,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${user.token}`,
                          },
                        },
                      );
                      setNewlyAddedCredentials([
                        ...newlyAddedCredentials,
                        {
                          username: values.username,
                          password: values.password,
                        },
                      ]);
                    }

                    setAA(provider);
                  } catch (err) {
                    setFieldTouched('username', true, false);
                    setFieldError('username', extractError(err));
                    setAA();
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                Authorize
              </Button>
              {options && options.length === 0 && (
                <a
                  href="https://daexus.helpsite.com/articles/58216-how-to-find-and-authorize-your-adobe-analytics-credentials-for-the-free-account#to-find-your-adobe-analytics-login-details-username-and-secret-follow-the-steps-below"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    float: 'right',
                    fontSize: 12,
                    marginTop: 8,
                    color: '#314f61',
                  }}
                >
                  How do I find my Adobe Username and Secret?
                </a>
              )}
            </>
          ) : (
            <>
              <LoginAA />
              <div>
                <Typography color="error" variant="caption">
                  {user.error}
                </Typography>
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  !values.username || !values.password || AA === null || loading
                }
                endIcon={
                  loading &&
                  AA === null && (
                    <CircularProgress size={20} style={{ color: '#e8c94b' }} />
                  )
                }
                onClick={async () => {
                  try {
                    setLoading(true);
                    setFieldValue('reportSuite', null);
                    setFieldValue('metrics', []);
                    setFieldValue('dimensions', []);
                    setFieldValue('segments', []);
                    setAA(null);
                    const provider = AdobeAnalytics(
                      values.username,
                      values.password,
                    );
                    await provider.getReportSuites();
                    setAA(provider);
                  } catch (err) {
                    setFieldTouched('password', true, false);
                    setFieldError('password', extractError(err));
                    setAA();
                  } finally {
                    setLoading(false);
                  }
                }}
              >
                Authorize
              </Button>
              <a
                href="https://daexus.helpsite.com/articles/58216-how-to-find-and-authorize-your-adobe-analytics-credentials-for-the-free-account#to-find-your-adobe-analytics-login-details-username-and-secret-follow-the-steps-below"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  float: 'right',
                  fontSize: 12,
                  marginTop: 8,
                  color: '#314f61',
                  visited: '#314f61',
                }}
              >
                How do I find my Adobe Username and Secret?
              </a>
            </>
          )}
          {AA && (
            <>
              <Box my={2}>
                <Divider />
              </Box>
              <ReportSuite
                name="reportSuite"
                label="Report Suite"
                provider={AA}
                autoFocus={!connectionData.reportSuite}
              />
              <DateRange disabled={!AA} plan={user.plan} />
              <Divider />
              <Grid item xs>
                <Granularity
                  name="granularity"
                  label="Granularity"
                  disabled={!AA}
                />
              </Grid>
              <Metadata
                name="metrics"
                label="Metrics"
                list="metrics"
                provider={AA}
              />
              <Metadata
                name="dimensions"
                label="Dimensions"
                list="elements"
                provider={AA}
              />
              <Metadata
                name="segments"
                label="Segments"
                list="segments"
                provider={AA}
              />
              <MyInput
                name="top"
                label="Top"
                type="number"
                inputProps={{
                  min: 1,
                }}
                helperText="Number of top results to return (leave empty to pull all data)"
              />

              <Button
                variant="contained"
                color="secondary"
                disabled={!values.metrics.length || preview === null}
                endIcon={
                  preview.columns &&
                  !preview.rows &&
                  !preview.error && (
                    <CircularProgress size={20} style={{ color: '#e8c94b' }} />
                  )
                }
                onClick={() => onPreview(values)}
              >
                Preview
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!values.metrics.length || !isValid}
                type="submit"
                style={{ marginLeft: 8 }}
              >
                Submit
              </Button>
              {preview.columns && <SortableTable {...preview} />}
              {preview.rows && preview.rows.length > 10 && (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!values.metrics.length || !isValid}
                  type="submit"
                  style={{ marginLeft: 8 }}
                >
                  Submit
                </Button>
              )}
              {isDebug && (
                <>
                  <Typography variant="subtitle2">Debug</Typography>
                  <CodeFormatter>{values}</CodeFormatter>
                </>
              )}
            </>
          )}
        </Form>
      </div>
    </>
  );
}

export default SubApp;
