import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import teal from '@material-ui/core/colors/teal';
import { format, subDays } from 'date-fns';
import { Formik } from 'formik';
import SubApp from './SubApp';
import './tableauWorkaround.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blueGrey[500],
    },
    secondary: {
      main: teal[500],
    },
    background: {
      default: '#fff',
    },
  },
});

const formatDate = (date) =>
  String(date).startsWith('c') ? date : format(new Date(date), 'yyyy-MM-dd');

function App() {
  const [user, setUser] = useState({});

  let username = window.tableau.username || '';
  let usernameAlias = window.tableau.usernameAlias || '';
  let password = window.tableau.password || '';
  let connectionData = {};
  try {
    connectionData = JSON.parse(window.tableau.connectionData);
    // Backward compatibility, if no provider is set, we suppose it is AA
    if (connectionData.provider && connectionData.provider !== 'aa') {
      username = '';
      usernameAlias = '';
      password = '';
      connectionData = {};
    }
  } catch (err) {}

  const initialValues = {
    username: usernameAlias,
    password,
    reportSuite: connectionData.reportSuite || null,
    metrics: connectionData.metrics || [],
    dimensions: connectionData.dimensions || [],
    segments: connectionData.segments || [],
    startDate:
      connectionData.startDate || format(subDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: connectionData.endDate || format(new Date(), 'yyyy-MM-dd'),
    granularity: connectionData.granularity || 'day',
    top: connectionData.top ?? 10,
  };

  return (
    <ThemeProvider theme={theme}>
      <Container fixed maxWidth="sm">
        <CssBaseline />
        <Formik
          initialValues={initialValues}
          onSubmit={({ username, password, dEmail, dPassword, ...rest }) => {
            const premium = user.plan === 'pro' || user.plan === 'server';
            window.tableau.connectionName = 'Daexus Catalyst Connector';
            window.tableau.username = (premium && user.email) || username;
            window.tableau.password = (premium && user.token) || password;
            window.tableau.usernameAlias = username;
            rest.startDate = formatDate(rest.startDate);
            rest.endDate = formatDate(rest.endDate);
            rest.provider = 'aa';
            window.tableau.connectionData = JSON.stringify(rest);
            window.tableau.submit();
          }}
          render={(props) => (
            <SubApp {...props} user={user} setUser={setUser} />
          )}
        />
      </Container>
      <Container
        fixed
        maxWidth="sm"
        style={{ fontSize: 12, marginTop: 8, color: '#999' }}
      >
        Daexus Catalyst v{process.env.REACT_APP_VERSION}
        <a
          href="https://daexus.helpsite.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ float: 'right', color: '#314f61' }}
        >
          Have questions? Visit our Support Center
        </a>
      </Container>
    </ThemeProvider>
  );
}

export default App;
