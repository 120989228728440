import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Form, Formik } from 'formik';
import Login from './Login';
import LoginAA from './LoginAA';

const Auth = () => {
  const initialValues = {
    username: window.tableau.usernameAlias || '',
    password: window.tableau.password || '',
    dEmail: window.tableau.username || '',
    dPassword: window.tableau.password || '',
  };

  const isFree = window.tableau.username === window.tableau.usernameAlias;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={({ username, password, dEmail, dPassword }) => {
        if (isFree) {
          window.tableau.username = username;
          window.tableau.password = password;
          window.tableau.usernameAlias = username;
        } else {
          window.tableau.username = dEmail;
          window.tableau.password = dPassword;
        }

        window.tableau.submit();
      }}
    >
      <Form>
        {isFree ? (
          <>
            <Typography variant="h5">
              Please enter your Adobe Analytics credentials
            </Typography>
            <LoginAA />
          </>
        ) : (
          <>
            <Typography variant="h5">
              Please enter your Daexus credentials
            </Typography>
            <Login fullWidth />
          </>
        )}
        <Button variant="contained" color="primary" type="submit">
          Authorize
        </Button>
      </Form>
    </Formik>
  );
};

export default Auth;
