import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useFormikContext } from 'formik';

const reportSuiteFilterOptions = createFilterOptions({
  stringify: (option) => `${option.rsid || option}${option.site_title || ''}`,
});

const ReportSuite = ({ name, label, provider, autoFocus }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const { report_suites } = await provider.getReportSuites();
      setOptions(report_suites);
    };

    if (provider) {
      fetchData();
    }
  }, [provider]);

  const { setFieldValue, values } = useFormikContext();
  const loading = !options.length;

  return (
    <Autocomplete
      disablePortal
      disabled={!provider}
      disableClearable
      getOptionLabel={(option) => option.site_title || option}
      filterOptions={reportSuiteFilterOptions}
      renderOption={(option) =>
        option.site_title ? (
          <div>
            {option.site_title}
            <Typography variant="caption" color="textSecondary" display="block">
              {option.rsid}
            </Typography>
          </div>
        ) : (
          option
        )
      }
      getOptionSelected={(a, b) => a.rsid === b.rsid}
      loading={loading}
      options={options}
      value={values[name]}
      onChange={(ev, val) => {
        setFieldValue(name, val);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {provider && loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          autoFocus={autoFocus}
        />
      )}
      openOnFocus
    />
  );
};

export default ReportSuite;
