import { formatAndEscapeId, formatName } from './utils/formatters';

const HOURLY_GRANULARITY = 'hour';

const TYPE_MAPPING = {
  int: 'int',
  number: 'int',
  float: 'float',
  decimal: 'float',
  percent: 'float',
  datetime: 'datetime',
  time: 'datetime',
};

const getSchema = (metrics, dimensions, granularity, incrementalUpdate) => {
  const columns = [
    ...dimensions.map((x) => ({
      id: formatAndEscapeId(x),
      alias: formatName(x),
      dataType: 'string',
    })),
    ...metrics.map((x) => ({
      id: formatAndEscapeId(x),
      alias: formatName(x),
      dataType: TYPE_MAPPING[x.type] || 'int',
    })),
  ];

  let response = {
    id: 'poc_id',
    alias: 'Daexus Catalyst Connector',
    columns,
  };

  if (granularity !== 'none') {
    columns.unshift({
      id: 'datetime',
      alias: 'Date',
      dataType: granularity === HOURLY_GRANULARITY ? 'datetime' : 'date',
    });

    if (incrementalUpdate) {
      response.incrementColumnId = 'datetime';
    }
  }

  return [response];
};

export default getSchema;
