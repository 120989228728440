import React from 'react';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

const connectors = {
  aa: 'Adobe Analytics',
  fbads: 'Facebook Ads',
  jira: 'Jira',
  ga: 'Google Analytics',
};

const licenseMap = {
  aa: 'AA',
  fbads: 'facebook',
  jira: 'jira',
  ga: 'google',
};

const drawerWidth = 80;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

const getLicense = (provider, licenses = {}) =>
  (licenses[licenseMap[provider]] || {}).plan || 'free';

function Sidebar({ licenses }) {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <List>
        {Object.keys(connectors).map((key, index) => (
          <ListItem
            button
            key={key}
            style={{
              flexDirection: 'column',
            }}
            selected={key === 'aa'}
            href={`/${key}`}
            component="a"
          >
            <img
              alt={connectors[key]}
              src={`connector-${key}.png`}
              height={32}
            />
            <Box pt={'2px'} textAlign="center">
              <small>{connectors[key]}</small>
            </Box>
            <img
              src={`badge-${getLicense(key, licenses)}.png`}
              style={{ position: 'absolute', top: 0, right: 0 }}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;
