import React from 'react';
import { useFormikContext } from 'formik';
import Button from '@material-ui/core/Button';

const DatePresetButton = (props) => {
  const { values } = useFormikContext();

  return (
    <>
      <Button
        color="primary"
        variant={
          props.startDate === values.startDate &&
          props.endDate === values.endDate
            ? 'contained'
            : 'text'
        }
        style={{ textTransform: 'none' }}
        {...props}
        onClick={() => {
          props.startDateChangeHandler(props.startDate);
          props.endDateChangeHandler(props.endDate);
        }}
      />
    </>
  );
};

export default DatePresetButton;
