import React from 'react';
import MyInput from './MyInput';

const LoginAA = () => {
  return (
    <>
      <MyInput name="username" label="Adobe Username" />
      <MyInput name="password" label="Adobe Secret" type="password" />
    </>
  );
};

export default LoginAA;
