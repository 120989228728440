import { formatAndEscapeId } from './utils/formatters';
import reportProgress from './utils/reportProgress';

const twoDigitNumber = (input) => ('0' + input).slice(-2);

const getData = (pages, appendRows = () => {}) => {
  if (pages.length === 0) {
    return [];
  }

  let rows = [];
  const metrics = pages[0].report.metrics.map(formatAndEscapeId);
  const dimensions = pages[0].report.elements.map(formatAndEscapeId);
  /*
    Report types:
    Overtime: no dimensions (regardless of granularity)
    Trended: dimensions(s) + not-`None` granularity
    Ranked: dimension(s) + `None` granularity
  */
  const reportType = pages[0].report.type;

  if (reportType !== 'overtime' && reportType !== 'ranked') {
    // Maybe use reportType === 'trended' instead?
    dimensions.unshift('datetime');
  }

  const extractBreakdown = (list, level = 0, row = {}) =>
    list.forEach((line) => {
      if (level === 0 && line.year) {
        // Super ugly workaround for the date formatting
        row = {
          [dimensions[level]]: `${line.year}-${twoDigitNumber(
            line.month,
          )}-${twoDigitNumber(line.day)}${
            line.hour !== undefined
              ? ' ' + twoDigitNumber(line.hour) + ':00'
              : ''
          }`,
        };
      } else {
        row[dimensions[level]] = line.name;
      }

      if (line.breakdown) {
        extractBreakdown(line.breakdown, level + 1, row);
      } else if (level === dimensions.length - 1) {
        line.counts.forEach((count, i) => {
          row[metrics[i]] = Number(count);
        });
        rows.push({ ...row });
      }
    });

  pages.forEach((x, i) => {
    reportProgress(`Extracting breakdown for page ${i}`);
    extractBreakdown(x.report.data);
    appendRows(rows);
    // TODO: should reset pages before inserting the data?
    rows = [];
  });

  // TODO: this would return the last page only, if the previous TODO gets implemented
  // Returning every page would double de memory footprint, because appendRows callback function already does that
  return rows;
};

export default getData;
