import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';

function MyInput(props) {
  const [field, meta] = useField(props);
  const { validate, ...rest } = props;
  const errorText = meta.touched && meta.error;

  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      error={Boolean(errorText)}
      {...field}
      {...rest}
      helperText={errorText || props.helperText}
    />
  );
}

export default MyInput;
