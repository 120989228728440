import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import App from './App';
import Auth from './Auth';
import extractError from './extractError';
import getData from './getData';
import getSchema from './getSchema';
import authenticator from './authenticator';
import AdobeAnalytics from './connectors/aa';
import log from './utils/log';
import reportError from './utils/reportError';

const cookies = new Cookies();
window.DAEXUS_API_URL =
  cookies.get('daexus-api-url') || process.env.REACT_APP_API_URL;
window.EXTENDED_LOGS = cookies.get('daexus-extended-logs');

setTimeout(() => {
  const link = document.location.href;
  document.getElementById('loading').innerHTML = `
  <h1 style="color: #314f61;">How to access the Daexus Catalyst Connector in Tableau</h1>
  ${
    navigator.userAgent.includes('lectron')
      ? '<p><strong>NOTE:</strong> In order to access and use the connector, make sure the Daexus app is turned on and stays on for as long as you plan on using it.</p>'
      : ''
  }
  <br />
  <div style="display: flex; font-size: 20px;">
    <div style="flex: 1; text-align: center;"><img src="/stepLogo1.png" style="max-height: 100px;" /></div>
    <div style="flex: 1; text-align: center;"><img src="/stepLogo2.png" style="max-height: 100px;" /></div>
    <div style="flex: 1; text-align: center;"><img src="/stepLogo3.png" style="max-height: 100px;" /></div>
    <div style="flex: 1; text-align: center;"><img src="/stepLogo4.png" style="max-height: 100px;" /></div>
  </div>
  <div style="display: flex; font-size: 20px;">
    <div style="flex: 1; text-align: center;"><span style="color: #314f61;">Step 1</span><p style="font-size: 16px;">Open Tableau Desktop application</p></div>
    <div style="flex: 1; text-align: center;"><span style="color: #314f61;">Step 2</span><p style="font-size: 16px;">Access the Web Data Connector</p></div>
    <div style="flex: 1; text-align: center;"><span style="color: #314f61;">Step 3</span><p style="font-size: 16px;">Paste the connector link into Tableau <input type="button" onclick="navigator.clipboard.writeText('${link}')" value="Copy" /></p></div>
    <div style="flex: 1; text-align: center;"><span style="color: #314f61;">Step 4</span><p style="font-size: 16px;">Hit Enter</p></div>
  </div>
  <br />
  <div style="text-align: center;"><a href="#" id="seeSteps" style="font-size: 20px;" onclick="document.getElementById('steps').style.display = 'block'; document.getElementById('seeSteps').style.display = 'none';">Show step by step instructions with screenshots <img src="/chevron-down.png" style="height: 12px;" /></a></div>
  <div id="steps" style="display: none;">
  <div style="text-align: center;"><a href="#" style="font-size: 20px;" onclick="document.getElementById('steps').style.display = 'none'; document.getElementById('seeSteps').style.display = 'block';">Hide step by step instructions with screenshots <img src="/chevron-up.png" style="height: 12px;" /></a></div>
  <br />
  Use the Tableau Desktop application (not the browser) to access the Daexus
  Catalyst Connector, as follows:
  <ol>
    <li>
      Open your Tableau Desktop Application<br />
      <img src="/step1.png" style="max-width: 100%; margin-top: 8px; margin-bottom: 24px;" />
    </li>
    <li>
      Under <strong>"Connect to a Server"</strong>, click the
      <strong>"More"</strong> button and select
      <strong>"Web Data Connector"</strong><br />
      <img src="/step2.png" style="max-width: 100%; margin-top: 8px; margin-bottom: 24px;" />
    </li>
    <li>
      In the Web Data Connector URL section, paste this link:<br />
      <a id="link" href="${link}">${link}</a> <input type="button" onclick="navigator.clipboard.writeText('${link}')" value="Copy" /><br />
      <img src="/step3.png" style="max-width: 100%; margin-top: 8px; margin-bottom: 24px;" />
    </li>
  </ol>
  </div>
  <p>For more information on how to use the Daexus Catalyst Connector, reach out to our support team by emailing us at <a href="mailto:support@daexus.io">support@daexus.io</a> or via chat, on our <a href="https://www.daexus.io">website</a> or <a href="https://daexus.helpsite.com/">Support Center</a>.</p>
  <div style="font-size: 12px;">Daexus Catalyst v${
    process.env.REACT_APP_VERSION
  }</div>
  `;
}, 20);

const myConnector = window.tableau.makeConnector();

myConnector.init = async (initCallback) => {
  document.getElementById('loading').style.display = 'none';
  document.getElementById('root').style.display = 'block';

  try {
    window.gtag('event', 'Tableau', {
      event_category: 'version',
      event_label: window.tableau.platformVersion || 'unknown',
      non_interaction: true,
    });
  } catch (err) {}

  window.tableau.authType = window.tableau.authTypeEnum.basic;

  if (window.tableau.phase === window.tableau.phaseEnum.gatherDataPhase) {
    let auth;
    try {
      auth = await authenticator(
        window.tableau.username,
        window.tableau.password,
        window.tableau.usernameAlias,
      );
    } catch (err) {
      if (err.name === 'PremiumExpired') {
        return window.tableau.abortWithError(err.message);
      }

      const type =
        window.tableau.username === window.tableau.usernameAlias
          ? 'Adobe Analytics'
          : 'Daexus';
      return window.tableau.abortForAuth(
        `Please use your ${type} credentials to log in again.`,
      );
    }

    if (
      window.tableau.authPurpose === 'enduring' &&
      auth.user.plan !== 'server'
    ) {
      return window.tableau.abortWithError(
        'Only supported with Daexus Catalyst Connector Server plan.',
      );
    }

    window.tableau.username = auth.email || auth.username;
    window.tableau.password = auth.token || auth.secret;
    window.tableau.usernameAlias = auth.username;
    window.tableau.secret = auth.secret;
    window.tableau.plan = auth.user.plan;

    initCallback();
  } else {
    // Normally we would only show the UI during the interactive phase because
    // Basic authentication would show a generic/native modal popup,
    // However, it's not always the case due to a Tableau bug
    ReactDOM.render(
      window.tableau.phase === window.tableau.phaseEnum.authPhase ? (
        <Auth />
      ) : (
        <App />
      ),
      document.getElementById('root'),
      initCallback,
    );
  }
};

myConnector.getSchema = (schemaCallback) => {
  const { metrics, dimensions, granularity, startDate, endDate } = JSON.parse(
    window.tableau.connectionData,
  );

  const incrementalUpdate =
    !startDate.startsWith('c') && endDate.startsWith('c');

  const schema = getSchema(metrics, dimensions, granularity, incrementalUpdate);

  schemaCallback(schema);
};

myConnector.getData = async (
  { tableInfo, appendRows, incrementValue },
  doneCallback,
) => {
  const connectionData = JSON.parse(window.tableau.connectionData);
  log(
    `Pulling data from Adobe Analytics for account ${
      connectionData.reportSuite.rsid || connectionData.reportSuite
    }`,
  );
  try {
    connectionData.incrementValue = incrementValue;

    const plan = (window.tableau || {}).plan;
    const multithreaded = false; //plan === 'pro' || plan === 'server';
    const data = await AdobeAnalytics(
      window.tableau.usernameAlias || window.tableau.username,
      window.tableau.secret || window.tableau.password,
    ).getMultiReport(connectionData, multithreaded);

    getData(data, appendRows);
    doneCallback();
  } catch (error) {
    reportError('Adobe error: ' + extractError(error));
  }
};

window.tableau.registerConnector(myConnector);
