import React from 'react';
import {
  DesktopDateRangePicker as DateRangePicker,
  DateRangeDelimiter,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import { useFormikContext } from 'formik';
import MyInput from './MyInput';

const DatePickerField = ({
  startField,
  endField,
  maxDate = new Date('10000-01-01'),
  minDate = new Date('1970-01-01'),
  ...props
}) => {
  const { errors, setErrors, setValues, values } = useFormikContext();

  const errorMessages = {
    invalidDate: 'Invalid date format',
    disablePast: 'Values in the past are not allowed',
    maxDate: `Date should not be after ${format(maxDate, 'P')}`,
    minDate: `Date should not be before ${format(minDate, 'P')}`,
  };

  return (
    <DateRangePicker
      reduceAnimations
      value={[values[startField], values[endField]]}
      inputFormat="yyyy-MM-dd"
      mask="____-__-__"
      minDate={minDate}
      maxDate={maxDate}
      onChange={([startDate, endDate]) =>
        setValues(
          {
            ...values,
            [startField]: startDate,
            [endField]: endDate,
          },
          false,
        )
      }
      renderInput={(
        { error: x1, helperText: x2, ref: startRef, ...startProps },
        { error: y3, helperText: y4, ref: endRef, ...endProps },
      ) => (
        <>
          <MyInput {...startProps} inputRef={startRef} name={startField} />
          <DateRangeDelimiter> to </DateRangeDelimiter>
          <MyInput {...endProps} inputRef={endRef} name={endField} />
        </>
      )}
      onError={([startDateReason, endDateReason]) =>
        setErrors({
          ...errors,
          [startField]: errorMessages[startDateReason],
          [endField]: errorMessages[endDateReason],
        })
      }
      PopperProps={{
        placement: 'top',
      }}
      {...props}
    />
  );
};

export default DatePickerField;
