import http from 'axios';

const log = (message) => {
  if (window.EXTENDED_LOGS) {
    return http.post(`${window.DAEXUS_API_URL}/log`, {
      message,
    });
  }

  if (window.tableau) {
    window.tableau.log(message);
  }
};

export default log;
