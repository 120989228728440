import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useFormikContext } from 'formik';
import { formatId, formatName } from './utils/formatters';

const metadataFilterOptions = createFilterOptions({
  stringify: (option) =>
    `${option.id || option}${option.name || ''}${option.classification || ''}`,
});

const Metadata = ({ name, label, provider, list }) => {
  const { setFieldValue, values } = useFormikContext();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const metadata = await provider.getMetadata(values.reportSuite);
      const result = metadata[0][list];
      if (list === 'elements') {
        const classifications = metadata[0].element_classifications || [];
        if (classifications.length) {
          const mapping = {};
          classifications.forEach((classification) => {
            mapping[classification.id] = classification.classifications;
          });
          result.forEach((element) => {
            const classificationList = mapping[element.id] || [];
            classificationList.forEach((classification) =>
              result.push({
                ...element,
                classification: classification.name,
              }),
            );
          });
        }
      }

      result.sort((a, b) => {
        const aName = formatName(a, '\0');
        const bName = formatName(b, '\0');

        if (aName < bName) {
          return -1;
        } else if (aName > bName) {
          return 1;
        }

        return 0;
      });

      setOptions(result);
    };

    if (values.reportSuite) {
      fetchData();
    }
  }, [list, provider, values.reportSuite]);

  const loading = !options.length;
  const value = values[name] || [];
  const ids = value.map(formatId);

  return (
    <Autocomplete
      disablePortal
      disabled={!values.reportSuite}
      multiple
      disableClearable
      getOptionLabel={formatName}
      filterOptions={metadataFilterOptions}
      getOptionSelected={(a, b) => formatId(a) === formatId(b)}
      getOptionDisabled={(option) => ids.indexOf(formatId(option)) !== -1}
      loading={loading}
      options={options}
      value={value}
      onChange={(ev, selectedItems = []) => setFieldValue(name, selectedItems)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          variant="outlined"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {values.reportSuite && loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(option) => (
        <div>
          {formatName(option)}
          {option.name && (
            <Typography variant="caption" color="textSecondary" display="block">
              {option.id}
            </Typography>
          )}
        </div>
      )}
    />
  );
};

export default Metadata;
