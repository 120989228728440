import React from 'react';
import MyInput from './MyInput';

function Login(props) {
  return (
    <>
      <MyInput name="dEmail" label="Email" {...props} />
      <MyInput
        name="dPassword"
        label="Password"
        type="password"
        style={{ display: 'block' }}
        {...props}
      />
    </>
  );
}

export default Login;
