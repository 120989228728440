import {
  add,
  format,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns';

const DATE_MAPPING = {
  hour: 'hours',
  day: 'days',
  week: 'weeks',
  month: 'months',
};

const STARTOF_FUNCTIONS = {
  day: startOfDay,
  week: startOfWeek,
  month: startOfMonth,
};

const getNextDate = (startDate, granularity) => {
  let start;
  if (granularity === 'hour') {
    start = parse(startDate, 'yyyy-MM-dd HH:mm', new Date());
  } else {
    start = parse(startDate, 'yyyy-MM-dd', new Date());
  }

  const increment = {};
  increment[DATE_MAPPING[granularity]] = 1;

  if (granularity === 'hour') {
    return format(add(start, increment), 'yyyy-MM-dd HH:mm');
  }

  return format(add(start, increment), 'yyyy-MM-dd');
};

export const splitDatesIntoIntervals = (
  startDate,
  endDate,
  granularity,
  maxIntervals,
) => {
  if (Object.keys(DATE_MAPPING).indexOf(granularity) === -1) {
    // Fail gracefully if can't understand the granularity
    return [[startDate, endDate]];
  }
  if (granularity === 'hour') {
    return [[startDate, endDate]];
  }

  if (startDate === endDate) {
    return [[startDate, endDate]];
  }

  const increment = {};
  increment[DATE_MAPPING[granularity]] = 1;

  let dates = [];
  let startInterval = parse(startDate, 'yyyy-MM-dd', new Date());
  let endAt = parse(endDate, 'yyyy-MM-dd', new Date());

  while (startInterval <= endAt) {
    let endInterval = sub(
      add(STARTOF_FUNCTIONS[granularity](startInterval), increment),
      { days: 1 },
    );
    if (endInterval > endAt) {
      endInterval = endAt;
    }
    dates.push([
      format(startInterval, 'yyyy-MM-dd'),
      format(endInterval, 'yyyy-MM-dd'),
    ]);
    startInterval = add(
      STARTOF_FUNCTIONS[granularity](startInterval),
      increment,
    );
  }

  // Distribute the dates as evenly as possible( note that the first chunks might containing more than the last)
  const datesPerInterval = Math.floor(dates.length / maxIntervals);
  let datesByInterval = new Array(maxIntervals).fill(datesPerInterval);
  let chunksLeft = dates.length - datesPerInterval * maxIntervals;
  for (let i = 0; i < chunksLeft; i++) {
    datesByInterval[i] = datesByInterval[i] + 1;
  }

  let intervals = [];
  let startPosition = 0;
  let chunk = 0;
  while (startPosition < dates.length) {
    let datesInChunk = dates.slice(
      startPosition,
      startPosition + datesByInterval[chunk],
    );
    // Todo: write test for 1 length
    intervals.push([
      datesInChunk[0][0],
      datesInChunk[datesInChunk.length - 1][1],
    ]);
    startPosition += datesByInterval[chunk];
    chunk++;
  }
  return intervals;
};

export default getNextDate;
