import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import http from 'axios';
import { useFormikContext } from 'formik';
import extractError from './extractError';
import AdobeAnalytics from './connectors/aa';
import LoginAA from './LoginAA';

const usernameFilterOptions = createFilterOptions();

const Username = ({
  label,
  autoFocus,
  setAA,
  disabled,
  token,
  options,
  setOptions,
}) => {
  const [fetchCount, setFetchCount] = useState(0);
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    username: '',
    password: '',
    error: '',
    loading: false,
  });

  const { setFieldValue, values, errors } = useFormikContext();
  const handleClose = () => {
    setDialogValue({
      username: '',
      password: '',
      error: '',
      loading: false,
    });

    toggleOpen(false);
    setAA(undefined);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let username;
    let password;
    if (options && options.length > 0) {
      ({ username, password } = dialogValue);
    } else {
      username = values.username;
      password = values.password;
    }
    try {
      setDialogValue({
        username,
        password,
        loading: true,
      });
      const provider = AdobeAnalytics(username, password);
      await provider.getReportSuites();
      let crendentialExists = false;
      if (options && options.length > 0) {
        options.forEach(crendential => {
          if (crendential.username == username && crendential.password == password) {
            crendentialExists = true;
          }
        })
      }
      if (!crendentialExists) {
        await http.post(
          `${window.DAEXUS_API_URL}/credential`,
          {
            username,
            password,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      }
      setFieldValue('username', username);
      setFieldValue('password', password);
      handleClose();
      setFetchCount(fetchCount + 1);
    } catch (err) {
      setDialogValue({
        username,
        password,
        error: extractError(err),
        loading: false,
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await http.get(`${window.DAEXUS_API_URL}/credential`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOptions(data);
    };

    fetchData();
  }, [fetchCount, token, setOptions]);

  return (
    <>
      {options && options.length > 0 && (
        <Autocomplete
          disabled={disabled}
          disablePortal
          disableClearable
          getOptionLabel={(option) => option.username || option}
          groupBy={(option) => 'Adobe Analytics'}
          getOptionSelected={(a, b) => (a.username || a) === (b.username || b)}
          loading={!options}
          options={options || []}
          value={values.username}
          onChange={(ev, val) => {
            if (val && val.isNew) {
              toggleOpen(true);
              setDialogValue({
                username: val.inputValue,
                password: '',
              });
            } else {
              setFieldValue('username', val.username || val);
              setFieldValue('password', val.password || '');
              setAA(undefined);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = usernameFilterOptions(options, params);

            filtered.unshift({
              isNew: true,
              inputValue: params.inputValue,
              username: `-- Add a new credential -- ${params.inputValue}`,
            });

            return filtered;
          }}
          renderOption={(option) => (
            <Typography
              style={{
                fontStyle: option.isNew === true ? 'italic' : 'regular',
                color: option.isNew === true ? '#777' : '#314f61',
                fontWeight: option.isNew === true ? 'bolder' : 'regular',
              }}
            >
              {option.username}
            </Typography>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              fullWidth
              variant="outlined"
              margin="normal"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {!options ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              autoFocus={autoFocus}
              error={Boolean(errors.username)}
              helperText={errors.username}
            />
          )}
        />
      )}
      {options && !options.length && <LoginAA />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form id="linkForm">
          <DialogTitle id="form-dialog-title">
            Connect to Adobe Analytics
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter your Adobe Analytics credentials below
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.username}
              onChange={(event) =>
                setDialogValue({ ...dialogValue, username: event.target.value })
              }
              label="Username"
              type="text"
              fullWidth
              error={Boolean(dialogValue.error)}
              disabled={dialogValue.loading}
            />
            <TextField
              margin="dense"
              id="name"
              value={dialogValue.password}
              onChange={(event) =>
                setDialogValue({ ...dialogValue, password: event.target.value })
              }
              label="Secret"
              type="password"
              fullWidth
              error={Boolean(dialogValue.error)}
              helperText={dialogValue.error}
              disabled={dialogValue.loading}
            />
            <div>
              <a
                href="https://daexus.helpsite.com/articles/58216-how-to-find-and-authorize-your-adobe-analytics-credentials-for-the-free-account#to-find-your-adobe-analytics-login-details-username-and-secret-follow-the-steps-below"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  float: 'right',
                  fontSize: 12,
                  marginTop: 8,
                  color: '#314f61',
                  visited: '#314f61',
                }}
              >
                How do I find my Adobe Username and Secret?
              </a>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              onClick={handleSubmit}
              variant="contained"
              disabled={
                dialogValue.loading ||
                !Boolean(dialogValue.username && dialogValue.password)
              }
              endIcon={
                dialogValue.loading && (
                  <CircularProgress color="inherit" size={20} />
                )
              }
            >
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Username;
