import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { useFormikContext } from 'formik';
import DatePickerField from './DatePickerField';
import MyInput from './MyInput';
import DatePresetButton from './components/DatePresetButton';
import DateExpression from './utils/DateExpression';
import DividerWithText from './components/DividerWithText';

const DateRange = ({ disabled, plan }) => {
  const { setFieldValue, values } = useFormikContext();
  const [value, setValue] = useState(
    Number(
      String(values.startDate).startsWith('c') ||
        String(values.endDate).startsWith('c'),
    ),
  );
  const handleChange = (event, newValue) => {
    if (newValue) {
      startDateExpressionChanged('');
      endDateExpressionChanged('');
    } else {
      try {
        setFieldValue('startDate', DateExpression(values.startDate), false);
        setFieldValue('endDate', DateExpression(values.endDate), false);
      } catch (err) {}
    }
    setValue(newValue);
  };

  let startDateResolved;
  let endDateResolved;
  try {
    startDateResolved = DateExpression(values.startDate);
    endDateResolved = DateExpression(values.endDate);
  } catch (err) {}

  const [startDateHelper, setStartDateHelper] = useState({
    text: value
      ? `Start Date: ${startDateResolved}`
      : 'Please enter a valid start date expression',
    error: false,
  });
  const startDateExpressionChanged = (value) => {
    setFieldValue('startDate', value, false);
    try {
      if (value !== '') {
        const date = DateExpression(value);
        setStartDateHelper({
          text: `Start Date: ${date}`,
          error: false,
        });
      } else {
        setStartDateHelper({
          text: 'Please enter an expression for the start date (e.g. cd-14d)',
          error: false,
        });
      }
    } catch (err) {
      setStartDateHelper({
        text: (
          <>
            {err.message}
            <br />
            See:{' '}
            <a
              href="https://docs.adobe.com/content/help/en/analytics/analyze/report-builder/data-requests/date-ranges/customized-date-expressions/examples-of-date-ranges-using-customized-expressions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Adobe Documentation
            </a>
          </>
        ),
        error: true,
      });
    }
  };

  const [endDateHelper, setEndDateHelper] = useState({
    text: value
      ? `Start Date: ${endDateResolved}`
      : 'Please enter a valid end date expression',
    error: false,
  });
  const endDateExpressionChanged = (value) => {
    setFieldValue('endDate', value, false);
    try {
      if (value !== '') {
        const date = DateExpression(value);
        setEndDateHelper({
          text: `End Date: ${date}`,
          error: false,
        });
      } else {
        setEndDateHelper({
          text: 'Please enter an expression for the end date (e.g. cd-1d)',
          error: false,
        });
      }
    } catch (err) {
      setEndDateHelper({
        text: (
          <>
            {err.message}
            <br />
            See:{' '}
            <a
              href="https://docs.adobe.com/content/help/en/analytics/analyze/report-builder/data-requests/date-ranges/customized-date-expressions/examples-of-date-ranges-using-customized-expressions.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Adobe Documentation
            </a>
          </>
        ),
        error: true,
      });
    }
  };

  const dateExpressionEnabled = plan === 'pro' || plan === 'server';

  // "Demote" rolling dates to fixed dates
  // if (value === 1 && !dateExpressionEnabled) {
  //   setFieldValue('startDate', DateExpression('cw-1w'), false);
  //   setFieldValue('endDate', DateExpression('cw-1d'), false);
  //   setValue(0);
  // }

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <Tabs value={value} onChange={handleChange} variant="fullWidth">
        <Tab label="Fixed Dates" />
        <Tab
          label={
            <>
              Rolling dates
              {!dateExpressionEnabled && (
                <sub style={{ color: 'red' }}>Available with premium plans</sub>
              )}
            </>
          }
          disabled={!dateExpressionEnabled}
        />
      </Tabs>
      {value === 0 && (
        <DatePickerField
          startField="startDate"
          startText="Start Date"
          endField="endDate"
          endText="End Date"
          disabled={disabled}
        />
      )}
      {value === 1 && (
        <>
          <>
            Use{' '}
            <a
              href="https://docs.adobe.com/content/help/en/analytics/analyze/report-builder/data-requests/date-ranges/customized-date-expressions/examples-of-date-ranges-using-customized-expressions.html"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#314f61' }}
            >
              Adobe Style date expressions
            </a>{' '}
            for custom date ranges
          </>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MyInput
                name="startDate"
                label="Start Date Expression"
                onChange={(event) =>
                  startDateExpressionChanged(event.target.value)
                }
                helperText={startDateHelper.text}
                error={startDateHelper.error}
              />
            </Grid>
            <Grid item xs={6}>
              <MyInput
                name="endDate"
                label="End Date Expression"
                onChange={(event) =>
                  endDateExpressionChanged(event.target.value)
                }
                helperText={endDateHelper.text}
                error={endDateHelper.error}
              />
            </Grid>
          </Grid>
          <Box bgcolor="#F5F5F5">
            <DividerWithText>
              Or pick one of the existing presets
            </DividerWithText>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <DatePresetButton
                  startDate="cw-1w"
                  endDate="cw-1d"
                  startDateChangeHandler={startDateExpressionChanged}
                  endDateChangeHandler={endDateExpressionChanged}
                >
                  Last Week
                </DatePresetButton>
              </Grid>
              <Grid item xs={3}>
                <DatePresetButton
                  startDate="cm-1m"
                  endDate="cm-1d"
                  startDateChangeHandler={startDateExpressionChanged}
                  endDateChangeHandler={endDateExpressionChanged}
                >
                  Last Month
                </DatePresetButton>
              </Grid>
              <Grid item xs={3}>
                <DatePresetButton
                  startDate="cd-7d"
                  endDate="cd-1d"
                  startDateChangeHandler={startDateExpressionChanged}
                  endDateChangeHandler={endDateExpressionChanged}
                >
                  Last 7 Days
                </DatePresetButton>
              </Grid>
              <Grid item xs={3}>
                <DatePresetButton
                  startDate="cd-14d"
                  endDate="cd-1d"
                  startDateChangeHandler={startDateExpressionChanged}
                  endDateChangeHandler={endDateExpressionChanged}
                >
                  Last 14 Days
                </DatePresetButton>
              </Grid>
              <Grid item xs={3}>
                <DatePresetButton
                  startDate="cd-30d"
                  endDate="cd-1d"
                  startDateChangeHandler={startDateExpressionChanged}
                  endDateChangeHandler={endDateExpressionChanged}
                >
                  Last 30 Days
                </DatePresetButton>
              </Grid>
              <Grid item xs={3}>
                <DatePresetButton
                  startDate="cd-90d"
                  endDate="cd-1d"
                  startDateChangeHandler={startDateExpressionChanged}
                  endDateChangeHandler={endDateExpressionChanged}
                >
                  Last 90 Days
                </DatePresetButton>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </LocalizationProvider>
  );
};

export default DateRange;
