import http from 'axios';
import AdobeAnalytics from './connectors/aa';

const authenticator = async (username, password, usernameAlias) => {
  const result = {
    // AA credentials
    username: '',
    secret: '',
    // Daexus credentials
    email: '',
    password: '',
    token: '',
    // Cache
    user: {},
    credentials: [],
  };

  // 1. Premium user
  if (username !== usernameAlias) {
    // 1.1. JWT Token
    if (password.startsWith('ey')) {
      const { data: user } = await http.get(
        `${window.DAEXUS_API_URL}/user/me`,
        {
          headers: { Authorization: `Bearer ${password}` },
        },
      );

      result.email = user.email;
      result.token = user.token; // Use the refreshed token to extend user's session
      result.user = user;
    }
    // 1.2. Email/password
    else {
      const { data: user } = await http.post(
        `${window.DAEXUS_API_URL}/user/login`,
        {
          email: username,
          password,
        },
      );

      result.email = user.email;
      result.password = password;
      result.token = user.token; // Use the refreshed token to extend user's session
      result.user = user;
    }

    // Only try to match a credential if one has been selected
    if (usernameAlias) {
      if (result.user.plan === 'pro' || result.user.plan === 'server') {
        // Match saved credential
        const { data: credentials } = await http.get(
          `${window.DAEXUS_API_URL}/credential`,
          {
            headers: { Authorization: `Bearer ${result.token}` },
          },
        );

        result.credentials = credentials;

        const matchedCredential = credentials.find(
          (x) => x.username === usernameAlias,
        );
        if (matchedCredential) {
          result.username = matchedCredential.username;
          result.secret = matchedCredential.password;
        }
      } else {
        const err = new Error(
          'This workbook was created using premium features. To continue using it, please upgrade your free plan in your Daexus profile: https://profile.daexus.io',
        );
        err.name = 'PremiumExpired';
        throw err;
      }
    }
  }
  // 2. Free user
  else {
    await AdobeAnalytics(username, password).getReportSuites();
    result.username = username;
    result.secret = password;
  }

  return result;
};

export default authenticator;
