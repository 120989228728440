import {
  add,
  format,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns';
import { parse, SymbolNode } from 'mathjs';

export class DateExpressionSyntaxError extends Error {
  // Raised when the error is due to a problem with the syntax or rules of the Date Expression
  name = 'DateExpressionError';
}

const dateFromExpression = function (expressionOrDate, firstDayOfWeek) {
  // For now the function assumes the week starts on Sunday.
  // but the prototype supports the firstDayOfWeek which will be used in the future for this purpose.
  // First, check if the parameter is not a date already.
  const dateFormatRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
  if (dateFormatRegex.test(expressionOrDate)) {
    return expressionOrDate;
  }

  let dateExpression;
  try {
    dateExpression = parse(expressionOrDate);
  } catch (err) {
    if (err instanceof SyntaxError) {
      throw new DateExpressionSyntaxError(
        'Invalid date expression, encountered the following error while trying to parse: ' +
          err.message,
      );
    } else {
      throw new Error('Unknown error: ' + err.message);
    }
  }

  const tree = replaceImplicitNodes(dateExpression);
  checkForInvalidOperations(tree);

  ensureFirstItemIsCurrent(tree);

  return format(parseNode(tree), 'yyyy-MM-dd');
};

const replaceImplicitNodes = function (tree) {
  // Eliminate any implicit multiplications inserted by math.js
  // For math.js each node of the form [number][letter] (e.g. `5d`) becomes an operation of the form [number] * [letter] (e.g. `5d` becomes `5` * `d`)
  const transformed = tree.transform(function (node, path, parent) {
    if (
      node.isOperatorNode &&
      node.fn === 'multiply' &&
      node.implicit === true &&
      (node.args[0].isConstantNode || node.args[0].isSymbolNode) &&
      (node.args[1].isConstantNode || node.args[1].isSymbolNode)
    ) {
      return new SymbolNode(
        '' +
          (node.args[0].value || node.args[0].name) +
          (node.args[1].value || node.args[1].name),
      );
    } else {
      return node;
    }
  });

  return transformed;
};

const checkForInvalidOperations = function (tree) {
  // For now we don't support any operations except addition and subtraction
  const allowedOperators = ['+', '-'];

  let notAllowedOperators = [];
  tree.traverse(function (node, path, parent) {
    if (node.isOperatorNode && allowedOperators.indexOf(node.op) < 0) {
      notAllowedOperators.push(node.op);
    }
  });

  if (notAllowedOperators.length > 0) {
    throw new DateExpressionSyntaxError(
      'The following operators are not supported by date expressions: ' +
        notAllowedOperators.join(','),
    );
  }
};

const ensureFirstItemIsCurrent = function (firstNode) {
  let node = firstNode;

  // Get the leftmost symbol
  while ((node.args || []).length > 0) {
    node = node.args[0];
  }

  if (node.isSymbolNode && node.name && node.name[0] === 'c') {
    return;
  }

  throw new DateExpressionSyntaxError(
    "The leftmost element needs to be one of 'cd', 'cw', 'cm' or 'cy'",
  );
};

const parseNode = function (node) {
  if (node.isSymbolNode || node.value) {
    return parseSymbolNode(node);
  } else {
    return parseOpNode(node);
  }
};

const parseSymbolNode = function (node) {
  const val = node.name || node.value;
  if (val === 'cd') {
    return new Date();
  }
  if (val === 'cw') {
    return startOfWeek(new Date(), { weekStartsOn: 0 });
  }
  if (val === 'cm') {
    return startOfMonth(new Date());
  }
  if (val === 'cy') {
    return startOfYear(new Date());
  }

  const re = /^([0-9]{1,4})([dwmy])/;
  if (re.test(val) === false) {
    // Todo: Add error message
    throw new DateExpressionSyntaxError('Cannot understand symbol: ' + val);
  }

  const components = re.exec(val);
  const mapping = {
    d: 'days',
    w: 'weeks',
    m: 'months',
    y: 'years',
  };

  let retVal = {};
  retVal[mapping[components[2]]] = components[1];
  return retVal;
};

const parseOpNode = function (node) {
  const left = parseNode(node.args[0]);
  const right = parseNode(node.args[1]);

  // cd, cw, cm should never be the right-most operand since they can only (mutually exclusive), appear at the left-most part.
  // this means that if the `right` argument is a date instance, c[dwm] has appeared somewhere inside the expression.
  if (right instanceof Date) {
    throw new DateExpressionSyntaxError(
      "'cd', 'cw', 'cm' and 'cy' can only appear as the leftmost element in the date expression",
    );
  }

  if (node.op === '+') {
    return add(left, right);
  } else {
    return sub(left, right);
  }

  // Unreachable code - commented
  // return null;
};

export default dateFromExpression;
